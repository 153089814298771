/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  /* Set background color */
}

.content {
  margin-left: 220px;
  /* Adjust to match the width of the menu */
  padding: 20px;
}

h1 {
  margin-top: 0;
}

/* Other styles for specific components can be added here */